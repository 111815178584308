import React from 'react';

import { Section, Container } from '@components/global';

import FaqItem from '@common/FaqItem';
// import ExternalLink from '@common/ExternalLink';

const FAQS = [
  {
    title: 'How do I get a device?',
    content: () => (
      <>
        Submit an application using one of the links above and we will be in touch
        with a decision on your application by the 9th of August.
      </>
    ),
  },
  {
    title: 'Do I have to be based in the UK to receive a device?',
    content: () => (
      <>
        Applications from those based in the UK will be considered first as they are 
        the easiest to process. We may be able to post some devices further though!
      </>
    ),
  },
  {
    title: 'Are there prizes for completing the programme?',
    content: () => (
      <>
        Yes! Every student that completes each update form will be given a prize, but we
        will be choosing some technical and experience-based prizes based on your suggestions.
        Let us know what you'd like in the application form.
      </>
    ),
  },
  // {
  //   title: 'How does do I future-proof my website?',
  //   content: () => (
  //     <>
  //       Do not build a website with last decade’s tech. The future of the web is
  //       mobile, JavaScript and APIs—the{` `}
  //       <ExternalLink href="https://jamstack.org/">JAMstack</ExternalLink>.
  //       Every website is a web app and every web app is a website. Gatsby.js is
  //       the universal JavaScript framework you’ve been waiting for.
  //     </>
  //   ),
  // },
];

const Faq = () => (
  <Section id="faq">
    <Container>
      <h1 style={{ marginBottom: 40 }}>Frequently Asked Questions</h1>
      <div>
        {FAQS.map(({ title, content }) => (
          <FaqItem title={title} key={title}>
            {content()}
          </FaqItem>
        ))}
      </div>
    </Container>
  </Section>
);

export default Faq;
