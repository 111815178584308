import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        art_fast: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "apply" }
        ) {
          childImageSharp {
            fluid(maxWidth: 480) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_learn: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "post" }
        ) {
          childImageSharp {
            fluid(maxWidth: 480) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_ideas: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "web" }
        ) {
          childImageSharp {
            fluid(maxWidth: 480) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="about">
        <Container>
          <Grid>
            <div>
              <h2>Apply for hardware</h2>
              <p>
                Ask a parent to fill out an application for a device. Microbits are aimed 
                at 11 - 14 year olds and Arduinos are aimed at 15-18 year olds. We're interested
                in how the device would help you this summer and what you're interested to learn. Video
                submissions are encouraged but not required.
              </p>
            </div>
            <Art>
              <Img fluid={data.art_fast.childImageSharp.fluid} />
            </Art>
          </Grid>
          <Grid inverse>
            <Art>
              <Img fluid={data.art_learn.childImageSharp.fluid} />
            </Art>
            <div>
              <h2>Receive hardware in the post</h2>
              <p>
                We will be assessing applications on a rolling basis and sending off hardware to top applicants
                who have submitted by midday on the 30th July, 2nd August and 4th August and 6th August.

                If you are not based in the UK, we will be assessing your application towards the end of the process
                and sending out hardware subject to availability.
              </p>
            </div>
          </Grid>
          <Grid>
            <div>
              <h2>Get started with activities in our programmes</h2>
              <p>
                Once you receive notification of acceptance onto the programme, we will send you
                a link to the activities and update forms.
                <br />
                Each Monday, starting from the 9th of August, yourself or a parent will be required to fill
                out an update form detailing the activities you carried out during the week. Every student that
                completes all of the forms will be sent a prize!
              </p>
            </div>
            <Art>
              <Img fluid={data.art_ideas.childImageSharp.fluid} />
            </Art>
          </Grid>
        </Container>
      </Section>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default About;
