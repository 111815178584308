import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';
import ExternalLink from '@common/ExternalLink';

// import { ReactComponent as MicrobitLogo } from '@images/logos/microbit.svg';
// import { ReactComponent as ArduinoLogo } from '@images/logos/arduino.svg';

// const LOGOS = [
//   {
//     logo: MicrobitLogo,
//     link: 'https://airbnb.io',
//   },
//   {
//     logo: ArduinoLogo,
//     link: 'https://www.apple.com/in/music/',
//   },
// ];

const UsedBy = () => (
  <StaticQuery
    query={graphql`
      query {
        art_microbit: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "microbit" }
        ) {
          childImageSharp {
            fluid(maxWidth: 480) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

      art_arduino: file(
        sourceInstanceName: { eq: "art" }
        name: { eq: "arduino" }
      ) {
        childImageSharp {
          fluid(maxWidth: 480) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
    `}
    render={data => (
      <Section id="apply" accent>
        <StyledContainer>
          <div>
            <h1>Apply to take part in the challenge!</h1>
            {/* <LogoGrid>
              {LOGOS.map(({ logo, link }) => (
                <ExternalLink key={link} href={link}>
                  {logo()}
                </ExternalLink>
              ))}
              <StyledExternalLink href="https://github.com/ajayns/gatsby-absurd">
                  <h3>Apply for a Micro:bit (11-14 year olds) &nbsp;&#x2794; </h3>
                </StyledExternalLink>
                <StyledExternalLink href="https://github.com/ajayns/gatsby-absurd">
                <h3> Apply for an Arduino (15-18 year olds) &nbsp;&#x2794; </h3>
                </StyledExternalLink>
            </LogoGrid> */}

            <Grid>
              <div>
                <StyledExternalLink href="https://airtable.com/shrhwIEy7g4eWfsJ6">
                    <h3>Apply for a Micro:bit (11-14 year olds) &nbsp;&#x2794; </h3>
                  </StyledExternalLink>
              </div>
              <Art>
                <Img fluid={data.art_microbit.childImageSharp.fluid} />
              </Art>
            </Grid>
            <Grid inverse>
              <Art>
                <Img fluid={data.art_arduino.childImageSharp.fluid} />
              </Art>
              <div>
              <StyledExternalLink href="https://airtable.com/shrRBi4dlJtJ7tSts">
                  <h3> Apply for an Arduino (15-18 year olds) &nbsp;&#x2794; </h3>
                </StyledExternalLink>
              </div>
            </Grid>
          </div>
          {/* <Art>
            <Img fluid={data.art_story.childImageSharp.fluid} />
          </Art> */}

        </StyledContainer>
      </Section>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const LogoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 64px;
  justify-items: center;
  margin-top: 96px;

  a {
    svg {
      width: 100%;
    }
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  position: relative;

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-content: center;
  }

  color: ${props => props.theme.color.white.regular};
  
`;

const Text = styled.div`
  justify-self: center;

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: start;
  }

  h1, h2, h3, p {
    color: ${props => props.theme.color.white.regular};
  }
`;


const StyledExternalLink = styled(ExternalLink)`
  color: inherit;
  text-decoration: none;

  &:hover {
    color: ${props => props.theme.color.black.regular};
  }
`;

// const Art = styled.figure`
//   width: 600px;
//   position: absolute;
//   top: -12%;
//   right: 50%;

//   @media (max-width: ${props => props.theme.screen.lg}) {
//     top: 0;
//     right: 65%;
//     width: 500px;
//   }

//   @media (max-width: ${props => props.theme.screen.md}) {
//     display: none;
//   }
// `;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default UsedBy;
